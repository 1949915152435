
const getProductLinkAnalyticsPage = (page: string, theShop: boolean): string => {
  if (!theShop) {
    return page
  }

  return `The shop ${page[0].toLowerCase()}${page.substring(1)}`
}

const getProductLinks = (theShop: boolean = false) => {
  return {
    productGeneral: { path: 'product/:slug', analytics: { page: getProductLinkAnalyticsPage('Product', theShop), pageType: 'Product' } },
    productPerfume: { path: 'perfume/:slug', analytics: { page: getProductLinkAnalyticsPage('Perfume product', theShop), pageType: 'Product' } },
    // TODO what to do with gift set pages? - added on 11.03.2022 by sonatskiy
    // productGiftSet: { path: 'giftset/:slug', analytics: { page: ' product', pageType: 'Product' } },
    // productSkincareSet: { path: 'skincareset/:slug', analytics: { page: ' product', pageType: 'Product' } },
    // ATTN paths was taken from prod by productList request, so there are only actual live paths, old paths aren't included
    productMakeup: { path: 'makeup(.*)/:slug', analytics: { page: getProductLinkAnalyticsPage('Makeup product', theShop), pageType: 'Product' } },
    productSkincare: { path: 'skincare(.*)/:slug', analytics: { page: getProductLinkAnalyticsPage('Skincare product', theShop), pageType: 'Product' } },
    productPersonalCare: { path: 'personal-care(.*)/:slug', analytics: { page: getProductLinkAnalyticsPage('Personal care product', theShop), pageType: 'Product' } },
    productWellness: { path: 'wellness(.*)/:slug', analytics: { page: getProductLinkAnalyticsPage('Wellness product', theShop), pageType: 'Product' } },
    productCandle: { path: 'candle/:slug', analytics: { page: getProductLinkAnalyticsPage('Candle product', theShop), pageType: 'Product' } },
    productExtra: { path: 'extras/:slug', analytics: { page: getProductLinkAnalyticsPage('Extra product', theShop), pageType: 'Product' } },
    productLegacyBody: { path: 'body/:slug', analytics: { page: getProductLinkAnalyticsPage('Personal care product', theShop), pageType: 'Product' } },
    productDiffuser: { path: 'diffuser/:slug', analytics: { page: getProductLinkAnalyticsPage('Diffuser product', theShop), pageType: 'Product' } },
    productAccessory: { path: 'accessory/:slug', analytics: { page: getProductLinkAnalyticsPage('Accessory product', theShop), pageType: 'Product' } },
    productHomeFragranceAccessory: {
      path: 'home-fragrance-accessory/:slug',
      analytics: { page: getProductLinkAnalyticsPage('Home Fragrance Accessory product', theShop), pageType: 'Product' },
    },
    productCarFreshenerRefill: {
      path: 'car-freshener-refill/:slug',
      analytics: { page: getProductLinkAnalyticsPage('Car Freshener Refill product', theShop), pageType: 'Product' },
    },
    productCarFreshenerClip: {
      path: 'car-freshener-clip/:slug',
      analytics: { page: getProductLinkAnalyticsPage('Car Freshener Clip product', theShop), pageType: 'Product' },
    },
    productRoomSpray: { path: 'room-spray/:slug', analytics: { page: getProductLinkAnalyticsPage('Room Spray product', theShop), pageType: 'Product' } },
  }
}

// ATTN you shouldn't add type here to support autocomplete in absolute links
const links = {
  home: { path: '', analytics: { page: 'Home perfumes', pageType: 'Home' }, sitemap: { priority: 1 } },
  homeMen: { path: 'men', analytics: { page: 'Home colognes', pageType: 'Home' } },

  offer: { path: 'offer/:cpn', analytics: { page: 'Home perfumes', pageType: 'Home' } },
  offerMen: { path: 'offer/men/:cpn', analytics: { page: 'Home colognes', pageType: 'Home' } },

  freeTrial: { path: 'free-trial', analytics: { page: 'Free trial', pageType: 'Landing' } },
  freeTrialMen: { path: 'men/free-trial', analytics: { page: 'Free trial colognes', pageType: 'Landing' } },
  freeTrialCa: { path: 'free-trial-ca', analytics: { page: 'Free trial Canada', pageType: 'Landing' } },
  freeTrialMenCa: { path: 'men/free-trial-ca', analytics: { page: 'Free trial Canada colognes', pageType: 'Landing' } },
  secondMonthFree: { path: 'second-month-free', analytics: { page: 'Second month free', pageType: 'Landing' } },
  secondMonthFreeMen: { path: 'men/second-month-free', analytics: { page: 'Second month free colognes', pageType: 'Landing' } },
  freePerfume: { path: 'subscribe-freeperfume', analytics: { page: 'Free perfume', pageType: 'Landing' } },
  freePerfumeMen: { path: 'men/subscribe-freeperfume', analytics: { page: 'Free perfume colognes', pageType: 'Landing' } },
  nift: { path: 'nift', analytics: { page: 'Nift', pageType: 'Landing' } },
  bouqs: { path: 'bouqs', analytics: { page: 'Bouqs', pageType: 'Landing' } },
  andreDrummond: { path: 'andre-drummond', analytics: { page: 'Andre Drummond', pageType: 'Landing' } },

  register: { path: 'register', analytics: { page: 'Register', pageType: 'Register and Login' } },
  login: { path: 'register/log-in', analytics: { page: 'Login', pageType: 'Register and Login' } },
  logoff: { path: 'logoff' },
  magicLinkContinue: { path: 'register/continue', analytics: { page: 'Magic link continue', pageType: 'Register and Login' } },
  forgotPassword: { path: 'register/remind-password', analytics: { page: 'Remind password', pageType: 'Register and Login' } },
  resetPassword: { path: 'register/password-reset', analytics: { page: 'Reset password', pageType: 'Register and Login' } },
  recoverPassword: { path: 'register/recover-password', analytics: { page: 'Recover password', pageType: 'Register and Login' } },
  facebookEmail: { path: 'facebook-email', analytics: { page: 'Register via facebook no email', pageType: 'Register and Login' } },

  queue: { path: 'queue', analytics: { page: 'Queue', pageType: 'Queue' } },

  profile: {
    root: { path: 'profile', analytics: { page: 'Account', pageType: 'Account' } },
    subscriptionStatus: { path: 'subscription-status', analytics: { page: 'Account subscription status', pageType: 'Account' } },
    orderHistory: { path: 'order-history', analytics: { page: 'Account orders history', pageType: 'Account' } },
    personalInfo: { path: 'personal', analytics: { page: 'Account personal details & password', pageType: 'Account' } },
    billing: { path: 'billing', analytics: { page: 'Account billing', pageType: 'Account' } },
    shipping: { path: 'shipping', analytics: { page: 'Account shipping', pageType: 'Account' } },
    reviews: { path: 'reviews', analytics: { page: 'Account reviews', pageType: 'Account' } },
    removeAccountConfirmation: { path: 'remove-account-confirmation', analytics: { page: 'Remove account confirmation', pageType: 'Account' } },
  },

  subscription: {
    root: { path: 'subscription' },

    // TODO update analytics values when we have them - added on 2021-05-07 by maddoger
    main: { path: 'main', analytics: { page: 'Main', pageType: 'Main' }, sitemap: { priority: 0.5 } },
    cologneOfTheMonth: { path: 'cologne-of-the-month/:year/:month', analytics: { page: 'FOM colognes previous', pageType: 'FOM' } },
    perfumeOfTheMonth: { path: 'perfume-of-the-month/:year/:month', analytics: { page: 'FOM perfumes previous', pageType: 'FOM' } },
    currentCologneOfTheMonth: { path: 'cologne-of-the-month', analytics: { page: 'FOM colognes current', pageType: 'FOM' }, sitemap: { priority: 0.5 } },
    currentPerfumeOfTheMonth: { path: 'perfume-of-the-month', analytics: { page: 'FOM perfumes current', pageType: 'FOM' }, sitemap: { priority: 0.5 } },
    cologneOfTheMonthList: { path: 'cologne-of-the-month/all', analytics: { page: 'FOM colognes list', pageType: 'FOM' }, sitemap: { priority: 0.5 } },
    perfumeOfTheMonthList: { path: 'perfume-of-the-month/all', analytics: { page: 'FOM perfumes list', pageType: 'FOM' }, sitemap: { priority: 0.5 } },

    perfumes: { path: 'perfumes', analytics: { page: 'Smartsearch perfumes', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
    colognes: { path: 'colognes', analytics: { page: 'Smartsearch colognes', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
    beauty: { path: 'beauty', analytics: { page: 'Smartsearch beauty', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
  },

  newArrivals: {
    root: { path: 'new-arrivals' },
    perfumes: { path: 'perfumes', analytics: { page: 'New arrivals perfumes', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
    colognes: { path: 'colognes', analytics: { page: 'New arrivals colognes', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
  },
  bestsellers: {
    root: { path: 'bestsellers' },
    perfumes: { path: 'perfumes', analytics: { page: 'Bestsellers perfumes', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
    colognes: { path: 'colognes', analytics: { page: 'Bestsellers colognes', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
  },
  clean: {
    root: { path: 'clean' },
    perfumes: { path: 'perfumes', analytics: { page: 'Clean perfumes', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
    colognes: { path: 'colognes', analytics: { page: 'Clean colognes', pageType: 'Smartsearch' }, sitemap: { priority: 0.5 } },
  },

  shop: {
    root: { path: 'shop', analytics: { page: 'The shop all', pageType: 'Shop' } },

    accessories: { path: 'accessories', analytics: { page: 'Shop accessories', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    bathBody: { path: 'bath-and-body', analytics: { page: 'Shop bath and body', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    candlesHomeScents: { path: 'candles', analytics: { page: 'Shop candles and home scents', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    colognes: { path: 'colognes', analytics: { page: 'Shop full-size fragrances', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    perfumes: { path: 'perfumes', analytics: { page: 'Shop full-size fragrances', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    scentbirdCases: { path: 'cases', analytics: { page: 'Shop cases', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    theEdit: { path: 'the-edit', analytics: { page: 'Shop the edit', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    holiday: { path: 'holiday', analytics: { page: 'Shop holiday', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    coar: { path: 'confessions-of-a-rebel', analytics: { page: 'Shop Confessions of a Rebel page', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    samples: {
      root: { path: 'samples' },
      perfumes: { path: 'perfumes', analytics: { page: 'Shop samples perfumes', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
      colognes: { path: 'colognes', analytics: { page: 'Shop samples colognes', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    },
    travelSize: {
      root: { path: 'travel-size' },
      perfumes: { path: 'perfumes', analytics: { page: 'Shop travel-size perfumes', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
      colognes: { path: 'colognes', analytics: { page: 'Shop travel-size colognes', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    },
    miniBottles: {
      root: { path: 'mini-bottles' },
      perfumes: { path: 'perfumes', analytics: { page: 'Shop 20ml perfumes', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
      colognes: { path: 'colognes', analytics: { page: 'Shop 20ml colognes', pageType: 'Shop' }, sitemap: { priority: 0.5 } },
    },
    strawberryShortcake: { path: 'strawberry-shortcake' },
  },

  // Product
  ...getProductLinks(),
  productPerfumeLanding: { path: 'perfume-landing/:slug', analytics: { page: 'Perfume product landing', pageType: 'Product' } },
  productExclusive: { path: 'product-exclusive/:slug', analytics: { page: 'Ecommerce promo product', pageType: 'Product' } },
  ecommerce: {
    root: { path: 'ecommerce' },
    ...getProductLinks(true),
  },

  // Payment old links
  checkout: { path: 'subscription/payment', analytics: { page: 'Checkout', pageType: 'Checkout' } },
  subscribe: { path: 'subscribe', analytics: { page: 'Checkout lead', pageType: 'Checkout' } },
  queueRedeem: { path: 'queue/redeem', analytics: { page: 'Checkout buy queue', pageType: 'Checkout' } },
  tossInRedeem: { path: 'toss-in/redeem', analytics: { page: 'Checkout toss-in', pageType: 'Checkout' } },
  thankYou: { path: 'thankyou/:sessionId', analytics: { page: 'Thankyou', pageType: 'Thankyou' } },

  // gift card
  giftCardPayment: { path: 'giftcard/payment', analytics: { page: 'Checkout gift card', pageType: 'Checkout' } },
  giftCardClaim: { path: 'claim', analytics: { page: 'Gift card claim', pageType: 'Gift card claim' } },
  giftCardClaimThankYou: { path: 'thankyou/gift-card', analytics: { page: 'Thankyou', pageType: 'Thankyou' } },

  // gift subscription
  giftSubscriptionPayment: { path: 'gift/payment', analytics: { page: 'Checkout gift subscription', pageType: 'Checkout' } },
  giftSubscriptionRedeem: { path: 'gift/redeem', analytics: { page: 'Gift redeem', pageType: 'Checkout' } },
  giftSubscriptionRedeemThankYou: { path: 'thankyou/gift', analytics: { page: 'Thankyou', pageType: 'Thankyou' } },

  // Recommendations
  smartRecommendations: { path: 'smart-recommendations', analytics: { page: 'Smart recommendations', pageType: 'Slider quiz' }, sitemap: { priority: 0.5 } },
  scentProfile: { path: 'scent-profile', analytics: { page: 'Scent profile', pageType: 'Scent profile' } },

  collectionsWomen: { path: 'collections-women', analytics: { page: 'Perfume collections', pageType: 'Collections' }, sitemap: { priority: 0.5 } },
  collectionsMen: { path: 'collections-men', analytics: { page: 'Cologne collections', pageType: 'Collections' }, sitemap: { priority: 0.5 } },
  collectionWomen: { path: 'collections-women/:slug', analytics: { page: 'Collections perfumes', pageType: 'Collections' } },
  collectionMen: { path: 'collections-men/:slug', analytics: { page: 'Collections colognes', pageType: 'Collections' } },

  brands: { path: 'brands', analytics: { page: 'All brands', pageType: 'Brands' }, sitemap: { priority: 0.5 } },
  brand: { path: 'brand/:name', analytics: { page: 'Brand', pageType: 'Brand' } },
  // ATTN brand page is the only SEO page available, so we use analytics for it, every other pages like tags and notes are redirects
  seoPerfumes: { path: ':name-perfumes', analytics: { page: 'SEO Brand perfumes', pageType: 'Brand' } },
  seoColognes: { path: ':name-colognes', analytics: { page: 'SEO Brand colognes', pageType: 'Brand' } },

  featuredBrand: { path: 'featured-brand', analytics: { page: 'Featured brand' } },

  gifts: { path: 'gifts', analytics: { page: 'Gifts', pageType: 'Gifts' }, sitemap: { priority: 0.5 } },
  giftCard: { path: 'gift-card', analytics: { page: 'Gift card', pageType: 'Gifts' }, sitemap: { priority: 0.5 } },
  giftSets: {
    root: { path: 'gift-sets', analytics: { page: 'Gift sets', pageType: 'Gifts' } },
    perfumes: { path: 'perfumes', analytics: { page: 'Gift sets', pageType: 'Gifts' }, sitemap: { priority: 0.5 } },
    colognes: { path: 'colognes', analytics: { page: 'Gift sets', pageType: 'Gifts' }, sitemap: { priority: 0.5 } },
    unisex: { path: 'unisex', analytics: { page: 'Gift sets', pageType: 'Gifts' }, sitemap: { priority: 0.5 } },
  },
  giftSet: { path: 'giftset/:slug', analytics: { page: 'Gift set', pageType: 'Gifts' } },
  giftSubscription: { path: 'gift', analytics: { page: 'Gift subscription', pageType: 'Gifts' }, sitemap: { priority: 0.5 } },
  giftsUnder50: { path: 'gifts-under-50', analytics: { page: 'Gifts under $50', pageType: 'Gifts' }, sitemap: { priority: 0.5 } },

  invite: { path: 'invite', analytics: { page: 'Referral program', pageType: 'Referral program' } },
  referral: { path: 'referral/:channel/:personalCode', analytics: { page: 'Referral landing', pageType: 'Referral program' } },

  tracking: { path: 'tracking', analytics: { page: 'tracking' } },

  cardUpdate: { path: 'card-update', analytics: { page: 'Payment method update', pageType: 'Card update' } },
  cardUpdate60: { path: 'card-update-60', analytics: { page: 'Payment method update 60% discount', pageType: 'Card update' } },
  cardUpdate50: { path: 'card-update-50', analytics: { page: 'Payment method update 50% discount', pageType: 'Card update' } },
  cardUpdate10: { path: 'card-update-10', analytics: { page: 'Payment method update $4.95 off' }, pageType: 'Card update' },
  cardUpdateFreeCase: { path: 'case-card-update', analytics: { page: 'Payment method update free case', pageType: 'Card update' } },
  cardUpdateFreeProduct: { path: 'card-update-freeperfume', analytics: { page: 'Payment method update perfume free', pageType: 'Card update' } },

  caseSubscription: { path: 'case-subscription', analytics: { page: 'Case subscription', pageType: 'Case subscription' } },
  caseSubscriptionThankYou: { path: 'thankyou/case-subscription', analytics: { page: 'Thankyou', pageType: 'Thankyou' } },

  candleSubscription: { path: 'candle-subscription', analytics: { page: 'Candle subscription', pageType: 'Candle subscription' } },
  featuredCandle: { path: 'featured-candle', analytics: { page: 'Featured candle', pageType: 'Featured candle' } },
  candleSubscriptionThankYou: { path: 'thankyou/candle-subscription', analytics: { page: 'Thankyou', pageType: 'Thankyou' } },

  // Drift subscription
  driftSubscription: { path: 'car-scent-subscription', analytics: { page: 'Drift subscription', pageType: 'Drift subscription' } },
  driftSubscriptionPDP: { path: 'car-scent-subscription/:uid', analytics: { page: 'Drift subscription product', pageType: 'Drift subscription product' } },
  driftSubscriptionThankYou: { path: 'thankyou/car-scent-subscription', analytics: { page: 'Thankyou', pageType: 'Thankyou' } },

  samplesSubscription: { path: 'samples-subscription', analytics: { page: '1.5 mL subscription', pageType: '1.5 mL subscription' } },
  samplesSubscriptionThankYou: { path: 'thankyou/samples-subscription', analytics: { page: 'Thankyou', pageType: 'Thankyou' } },

  upgradeProduct: { path: 'upgrade-product', analytics: { page: 'Upgrade product number', pageType: 'Upgrade' } },
  downgrade: { path: 'downgrade', analytics: { page: 'downgrade' } },

  buyQueue: { path: 'buy-queue', analytics: { page: 'Buy queue', pageType: 'Buy queue' } },

  // exclusive: { path: 'exclusive', analytics: { page: 'exclusive fragrances' } },
  candles: { path: 'candles', analytics: { page: 'candle products' } },
  privateSale: { path: 'private-sale', analytics: { page: 'Private sale', pageType: 'Private sale' } },
  tossIn: { path: 'toss-in', analytics: { page: 'Toss in', pageType: 'Toss in' } },

  resubscribe: { path: 'resubscribe', analytics: { page: 'Resubscribe $5.95 off', pageType: 'Resubscribe' } },
  resubscribeNoOffer: { path: 're-subscribe', analytics: { page: 'Resubscribe no offer', pageType: 'Resubscribe' } },
  resubscribe50: { path: 'resubscribe-50', analytics: { page: 'Resubscribe 50% off', pageType: 'Resubscribe' } },
  resubscribe60: { path: 'resubscribe-60', analytics: { page: 'Resubscribe 60% off', pageType: 'Resubscribe' } },
  resubscribeFree: { path: 'resubscribe-free', analytics: { page: 'Resubscribe 1 month free', pageType: 'Resubscribe' } },
  resubscribeFreeProduct: { path: 'resubscribe-freeperfume', analytics: { page: 'Resubscribe free product', pageType: 'Resubscribe' } },
  resubscribeFreeCase: { path: 'case-prize', analytics: { page: 'Resubscribe free case', pageType: 'Resubscribe' } },

  team: { path: 'team', analytics: { page: 'team' }, sitemap: { priority: 0.5 } },
  press: { path: 'press', analytics: { page: 'press' }, sitemap: { priority: 0.5 } },
  // The slash was added at the request of SEO because the auto-replacement of the url on the blog pages takes place
  blog: { path: 'blog/', analytics: { page: 'blog' }, sitemap: { priority: 0.5 } },
  terms: { path: 'terms', analytics: { page: 'Terms and conditions', pageType: 'Legal' }, sitemap: { priority: 0.5 } },
  privacy: { path: 'privacy', analytics: { page: 'Privacy policy', pageType: 'Legal' }, sitemap: { priority: 0.5 } },
  ccpa: { path: 'ccpa', analytics: { page: 'CCPA', pageType: 'Legal' }, sitemap: { priority: 0.5 } },
  accessibility: { path: 'accessibility', analytics: { page: 'Accessibility', pageType: 'Legal' }, sitemap: { priority: 0.5 } },

  // notes
  notes: { path: 'notes', analytics: { page: 'Notes library', pageType: 'Notes' }, sitemap: { priority: 0.5 } },
  note: { path: 'note/:slug', analytics: { page: 'Note page', pageType: 'Note page' } },

  // fragrance family
  fragranceFamilies: { path: 'fragrance-families', analytics: { page: 'Fragrance Family Library page', pageType: 'Fragrance Family Library page' }, sitemap: { priority: 0.5 } },
  fragranceFamily: { path: 'fragrance-families/:slug', analytics: { page: 'Fragrance Family  page', pageType: 'Fragrance Family  page' } },

  request: { path: 'request', sitemap: { priority: 0.5 } },

  // collaborations
  saks: { path: 'saks', analytics: { page: 'Saks x Scentbird', pageType: 'Collaboration' }, sitemap: { priority: 0.5 } },
  scentbirdSelect: { path: 'scentbird-select', analytics: { page: 'Scentbird select', pageType: 'Scentbird select' }, sitemap: { priority: 0.5 } },

  scentHoroscope: { path: 'horoscope', analytics: { page: 'Horoscope page', pageType: 'Collections' }, sitemap: { priority: 0.5 } },
  scentHoroscopeSign: { path: 'horoscope/:sign', analytics: { page: 'Horoscope collection page', pageType: 'Collections' }, sitemap: { priority: 0.5 } },

  pr: {
    root: { path: 'pr', analytics: { page: 'Collection page', pageType: 'Collaboration' } },
    landing: { path: ':slug', analytics: { page: 'Collection page', pageType: 'Collaboration promo page' } },
    detailed: { path: ':slug/:nestedSlug', analytics: { page: 'Collection page', pageType: 'Collaboration collection page' } },
    quiz: { path: 'quiz/:slug', analytics: { page: 'Quiz collection page', pageType: 'Collaboration quiz page' } },
  },

  // Remove this link if CRO-69 ab test is unsuccessful
  inAppMessagesCenter: { path: 'messages-center', analytics: { page: 'Notification center', pageType: 'Notification center' } },

  // Limited Drop
  limitedDrop: { path: 'limited-drop/:slug?', analytics: { page: 'Limited Drop', pageType: 'Limited Drop' } },
  currentLimitedDrop: { path: 'limited-drop', analytics: { page: 'Limited Drop', pageType: 'Limited Drop' } },
  limitedDropProduct: { path: 'limited-drop/:slug/:productSlug', analytics: { page: 'Limited Drop The Vault Product page', pageType: 'Product' } },

  twelveDays: { path: '12days', analytics: { page: '12 days', pageType: 'Home' } },
  ellimanperks: { path: 'ellimanperks', analytics: { page: 'Elliman Perks', pageType: 'Home' } },
  accessPerks: { path: 'accessperks', analytics: { page: 'Access Development', pageType: 'Home' } },

  // Careers
  careers: { path: 'careers', analytics: { page: 'Careers', pageType: 'Careers' } },

  // Collaborations
  strawberryShortcake: { path: 'brand/strawberry-shortcake' },

  // Fragrance Awards Page
  fragranceAwards: { path: 'digital-fragrance-awards', analytics: { page: 'Fragrance Awards', pageType: 'Fragrance Awards' } },
}


export default links
